<script setup lang="ts">
import type { CmsElementProductSlider, ElementConfig, SliderElementConfig } from '@shopware-pwa/composables-next';
import { computed, inject, toRefs } from 'vue';
import { breakpointsTailwind, type UseBreakpointsReturn, useIntersectionObserver } from '@vueuse/core';
import type { AlgoliaProduct } from '~/types/models/algoliaProduct';
import type { Swiper as SwiperClass } from 'swiper/types';
import { useMultipleProductsTracking } from '~/composables/tracking/useMultipleProductsTracking';
import type { SliderNameType } from '~/types/tracking/sliderNameType';
import type { SharedModal } from '~/components/shared/SharedModal.vue';
import { v4 as uuidv4 } from 'uuid';

type ProductSliderConfig = CmsElementProductSlider['config'] & Record<string, ElementConfig<any>>;

export type ExtendedSliderElementConfig = Omit<SliderElementConfig, 'navigationArrows'> & {
    navigationArrows?: ElementConfig<'outside' | 'inside' | 'bottom' | ''>;
};

const props = defineProps<{
    config: ProductSliderConfig;
    products: AlgoliaProduct[];
    categoryType?: SliderNameType;
}>();

const sliderUniqueId = uuidv4().replace(/-/g, '');

const { products } = toRefs(props);

const { getConfigValue } = useCmsElementConfig({ config: props.config } as any);

const sliderConfig = computed<ExtendedSliderElementConfig>(() => ({
    minHeight: {
        value: '300px',
        source: 'static',
    },
    verticalAlign: {
        source: 'static',
        value: getConfigValue('verticalAlign') || '',
    },
    displayMode: {
        value: 'contain',
        source: 'static',
    },
    navigationDots: {
        value: 'outside',
        source: 'static',
    },
    navigationArrows: {
        value: 'bottom',
        source: 'static',
    },
    wrapperPadding: {
        value: false,
        source: 'static',
    },
}));

const { prepareAlgoliaProductsForTracking, addSliderImages } = usePrepareForTracking();
const { trackMultipleProducts } = useMultipleProductsTracking();
const { trackAlgoliaObjectView } = useAlgoliaTracking();

const breakpoints: UseBreakpointsReturn = inject('breakpoints') as UseBreakpointsReturn;

const slidesPerView: Record<string, number> = {
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 4,
    '2xl': 5,
};

// @ts-ignore expression not callable
const active = breakpoints.active();

// breakpoints.active() is indefined on mobile, that's why we fallback to xs
const currentSlidesPerView = computed(() => slidesPerView[active.value] ?? slidesPerView['xs']);

const activeSlideIndex = ref(0);
const currentlyVisibleObjectIds = computed(() =>
    products.value
        .slice(activeSlideIndex.value, activeSlideIndex.value + currentSlidesPerView.value)
        .map((product) => product.objectID),
);

const getMainSliderInstance = (slider: SwiperClass) => {
    if (slider.activeIndex < 0) return;

    slider.on('slideChange', () => {
        activeSlideIndex.value = slider.activeIndex;

        trackMultipleProducts(
            'view_item_list',
            prepareAlgoliaProductsForTracking(
                addSliderImages(products.value, slider.activeIndex, props.categoryType),
                undefined,
                props.categoryType,
            ),
        );
    });
};

const onSlideNext = () => {
    const newViewedId =
        currentSlidesPerView.value > 1
            ? currentlyVisibleObjectIds.value[currentSlidesPerView.value - 1]
            : currentlyVisibleObjectIds.value[0];
    trackAlgoliaObjectView({ objectIDs: [newViewedId] });
};

const onSlidePrev = () => {
    const newViewedId =
        currentSlidesPerView.value > 1
            ? currentlyVisibleObjectIds.value[currentSlidesPerView.value - 1]
            : currentlyVisibleObjectIds.value[0];
    trackAlgoliaObjectView({ objectIDs: [newViewedId] });
};

const autoplay = getConfigValue('rotate');
const border = getConfigValue('border');

const modal = inject<SharedModal>('modal') as SharedModal;
const selectedProductId = ref<null | string>(null);
const onAddWishlistProduct = (product: AlgoliaProduct) => {
    selectedProductId.value = product.productId;

    modal.open('WishlistsModalAddProductForm', `wishlist-add-product-${sliderUniqueId}-${product.productId}`, {
        productId: product.productId,
        minPurchase: product.minPurchase,
        purchaseSteps: product.purchaseSteps,
        algoliaProduct: product,
    });
};

const productSlider = ref(null);
const { stop } = useIntersectionObserver(productSlider, ([{ isIntersecting }]) => {
    if (isIntersecting) {
        trackMultipleProducts(
            'view_item_list',
            prepareAlgoliaProductsForTracking(
                addSliderImages(products.value, activeSlideIndex.value, props.categoryType),
                undefined,
                props.categoryType,
            ),
        );

        trackAlgoliaObjectView({ objectIDs: currentlyVisibleObjectIds.value });

        stop();
    }
});
</script>

<template>
    <div
        v-if="products.length"
        ref="productSlider"
        class="product-slider"
        :class="{ 'py-5 border border-gray-300': border }"
    >
        <SharedSlider
            :config="sliderConfig"
            :slider-options="{
                autoplay: autoplay,
                slidesPerView: slidesPerView['xs'],
                spaceBetween: 24,
                breakpoints: {
                    [breakpointsTailwind.sm]: {
                        slidesPerView: slidesPerView['sm'],
                    },
                    [breakpointsTailwind.md]: {
                        slidesPerView: slidesPerView['md'],
                        freeMode: {
                            enabled: true,
                            sticky: true,
                        },
                    },
                    [breakpointsTailwind.lg]: {
                        slidesPerView: slidesPerView['lg'],
                    },
                    [breakpointsTailwind.xl]: {
                        slidesPerView: slidesPerView['xl'],
                    },
                    [breakpointsTailwind['2xl']]: {
                        slidesPerView: slidesPerView['2xl'],
                    },
                },
                centerInsufficientSlides: true,
            }"
            class="space-x-6"
            @on-init="getMainSliderInstance"
            @slide-next-transition-end="onSlideNext"
            @slide-prev-transition-end="onSlidePrev"
        >
            <ProductCard
                v-for="(product, index) of products"
                :key="product.productId"
                class="h-full basis-full sm:basis-1/2 lg:basis-1/4 xl:basis-1/5"
                :product="product"
                :layout-type="getConfigValue('boxLayout')"
                :displayMode="getConfigValue('displayMode')"
                :index="index"
                type="slider"
                @add-wishlist-product="onAddWishlistProduct"
            />

            <template #loader>
                <div class="flex gap-4">
                    <SharedSkeletonLoader
                        class="flex-1 w-full p-3.5"
                        size="lg"
                    />

                    <SharedSkeletonLoader
                        v-for="skeletonLine in 4"
                        :key="skeletonLine"
                        class="flex-1 p-3.5 w-full !hidden sm:!flex"
                        size="lg"
                    />
                </div>
            </template>
        </SharedSlider>
        <SharedModalContent
            :id="`wishlist-add-product-${sliderUniqueId}-${selectedProductId}`"
            :modal="modal"
        />
    </div>
</template>

<style lang="scss" scoped>
:deep(.swiper-slide) {
    height: auto !important;
}
</style>
